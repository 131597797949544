import React from "react";
import { graphql } from "gatsby";
import AppBarPage from "../components/AppBarPage";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import "../css/global-styles.css";

const blueColorUrl = "#1565c0";

const appbarHeight = 64;
const verticalOffset = 6;
const defaultFontSize = 1.08; // in rem

const useStyles = makeStyles(() => ({
  content: {
    color: "#333",
    maxWidth: "700px",
    marginTop: appbarHeight + verticalOffset + 20,
    margin: "auto",
    fontSize: defaultFontSize + "rem",
    padding: "0px 16px 0px 16px"
  }
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function PageTemplate({ data }: any) {
  // this prop will be injected by the GraphQL query below.
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const classes = useStyles(data);

  return (
    <div>
      <AppBarPage title={frontmatter.title} />
      <CssBaseline />
      <div className={classes.content} dangerouslySetInnerHTML={{ __html: html }} />
      <style dangerouslySetInnerHTML={{ __html: `a {text-decoration: none; color:${blueColorUrl}}` }} />
      <Helmet>
        <title>{frontmatter.helmetTitle}</title>
        <meta name="description" content={frontmatter.helmetDescription} />
        <meta name="author" content="Talal Itani" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    </div>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        helmetTitle
        helmetDescription
      }
    }
  }
`;
